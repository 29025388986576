import React from "react";
import "./LoadingScreen.scss"; // Import the loading styles

const LoadingScreen = () => {
  return (
    <div className="full-screen-loader">
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingScreen;
